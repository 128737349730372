<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="tpLink">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('tpLink.deviceStatus')">
          <el-select v-model.trim="search.deviceStatus" :placeholder="$t('common.pleaseSelect')" style="width: 100%"
            clearable>
            <el-option v-for="item in deviceStatusList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <!-- <el-table-column prop="devId" :label="$t('tpLink.deviceName')" width="80"
        align="center"></el-table-column> -->
      <table-column prop="elevatorNo" :label="$t('dayWeekMonthManger.elevatorNo')" :width="140" align="center">
        <template #default="scope">
          <router-link :to="'/elevator/' + scope.row.elevatorId" target="_blank">
            {{ scope.row.elevatorNo }}
          </router-link>
        </template>
      </table-column>
      <table-column prop="deviceName" :label="$t('tpLink.deviceName')"></table-column>
      <table-column prop="elevatorName" :label="$t('tpLink.boundElevator')">
      </table-column>
      <table-column prop="deviceStatus" :label="$t('tpLink.deviceStatus')" :width="110" align="center">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.deviceStatus === 1">{{ $t("monitor.online") }}</el-tag>
          <el-tag type="info" v-else>{{ $t("monitor.offline") }}</el-tag>
        </template>
      </table-column>
      <table-column prop="deviceType" :label="$t('tpLink.deviceType')" align="center" :width="110"></table-column>
      <table-column prop="deviceModel" :label="$t('tpLink.deviceModel')" :width="140"></table-column>
      <table-column prop="ethernet" :label="$t('tpLink.mac')" :width="140"></table-column>
      <table-column :label="$t('dayWeekMonthManger.operate')" align="center" :width="170" :tooltip="false">
        <template #default="scope">
          <el-button type="text" :disabled="!scope.row.devId" @click="preview(scope.row.devId)">
            {{ $t("tpLink.preview") }}
          </el-button>
          <el-button type="text" @click="showPlayBack(scope.row.devId)">
            {{ $t("tpLink.playBack") }}
          </el-button>
          <el-button type="text" @click="$refs.videoAlarm.open(scope.row.devId)">
            {{ $t("tpLink.alarm") }}
          </el-button>
          <el-button type="text" @click="$refs.videoPeople.open(scope.row.devId)">
            {{ $t("tpLink.peoples") }}
          </el-button>
        </template>
      </table-column>
    </vm-table>
    <video-alarm ref="videoAlarm"></video-alarm>
    <video-people ref="videoPeople"></video-people>
    <t-p-link-play-back ref="playBack"></t-p-link-play-back>
  </div>
</template>
<script>
import VideoPeople from "@/views/vmVideo/TPLinkVideoPeople";
import VideoAlarm from "@/views/vmVideo/TPLinkVideoAlarm";
import TPLinkPlayBack from "@/views/tenant/tplink/TPLinkPlayBack";
import { Base64 } from "js-base64";
import auth from "@/util/auth";

export default {
  components: { VideoAlarm, VideoPeople, TPLinkPlayBack },
  data() {
    return {
      userName: auth.getUsername(),
      siteName: window.config.siteName,
      loading: true,
      refreshLoading: false,
      search: {
        filter: "",
        deviceStatus: "",
      },
      deviceStatusList: [
        { value: 1, label: this.$t("monitor.online") },
        { value: 0, label: this.$t("monitor.offline") },
      ],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    refresh() {
      this.refreshLoading = true;
      this.$http.get("tpLink/refresh").then(res => {
        this.refreshLoading = false;
        this.getList(1);
        this.$message.success(this.$t("tpLink.synchronizationSucceeded"));
      }).catch(err => {
        this.refreshLoading = false;
        this.$message.error(this.$t("tpLink.synchronizationFailed"));
      });
    },
    preview(devId) {
      this.$http.get("tpLink/getPreviewUrl").then(res => {
        window.open(res.data + "?devId=" + Base64.encode(devId.toString()));
      });
    },
    showPlayBack(devId) {
      this.$refs.playBack.open(devId);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  margin: 0 10px;
}
</style>
